import React, {useState} from 'react';
import Modal from "react-modal";
import { useFormik } from "formik";
import { Formik, FieldArray, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useCommonEnquiryMutation } from '../../Store/Features/CommonFormAPI';
import { useRouter } from 'next/navigation';
import { encode, decode } from '../../../utils/encrypt';

// Modal.setAppElement("#__next");
const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    message: Yup.string().required('Message is required'),
    friends: Yup.array().of(
        Yup.object().shape({
            friend_name: Yup.string().required("Friend's Name is required"),
            friend_email: Yup.string().email('Invalid email').required("Friend's Email is required"),
        })
    ),
});
const initialValues = {
    name: '',
    email: '',
    message: '',
    friends: [{ friend_name: '', friend_email: '' }],
};

const FriendFields = ({isOpen, closeModal, page_name, form_name, captcha = null}) => {

    const [formAPICallBack, { isLoading }] = useCommonEnquiryMutation();
    const [captchaCompleted, setcaptchaCompleted] = useState(false);
    const [showError, setShowError] = useState(false);

    
    const { push } = useRouter();
    const [addingFriend, setAddingFriend] = useState(false);
    const handleSubmit = (values) => {
        values.page_name = page_name;
        values.form_name = form_name;
        values.source_url = window.location.href;
        values.source_url_from = document.referrer;
        values.validation_token = captchaToken
        formAPICallBack({
        data : { data: encode(values) }, 
        url : '/enquiry/forward-to-friend'
        }).then((response) => {
            if (response) {
                if (response.data.code == 200) {
                    setTimeout(() => {
                        push('/thank-you');
                    }, 1000);
                } else {
                    alert(response.data.message)
                }

            } else {
                let res = {
                    'message': "Error",
                    'status': false
                }
            }
        })
    };
    if (!isOpen) return null;
    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-50" isOpen={isOpen} onRequestClose={closeModal} contentLabel="My Modal">
                <div className="modal fixed inset-0 bg-black opacity-50"></div>
                <div className="modal-content bg-white rounded-lg shadow-lg z-10 lg:w-[40%] w-[90%]">
                    <div className="modal-header flex justify-between p-4">
                    <h2 className="text-2xl font-semibold mx-auto">Forward To Friend</h2>
                    <button className="text-gray-600 hover:text-gray-800 text-xl" onClick={closeModal}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    </div>
                    <hr />
                    <div className="modal-body p-5" style={{height: '380px', overflowY: 'scroll'}}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                // Handle form submission here
                            }}>
                            {({ values, setFieldValue }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-6">
                                            <label htmlFor="name" className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Name*</label>
                                            <Field type="text" id="name" name="name" className="w-full h-10 p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"/>
                                            <ErrorMessage name="name" component="div" className="error text-sm text-[#FF0000]" />
                                        </div>
                                        <div className="col-span-6">
                                            <label htmlFor="email" className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Email*</label>
                                            <Field type="email" id="email" name="email" className="w-full h-10 p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"/>
                                            <ErrorMessage name="email" component="div" className="error text-sm text-[#FF0000]" />
                                        </div>
                                        <div className="col-span-12 mb-3">
                                            <label htmlFor="message" className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Message*</label>
                                            <Field as="textarea" rows= "4" id="message" name="message" className="w-full p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"/>
                                            <ErrorMessage name="message" component="div" className="error text-sm text-[#FF0000]" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='grid grid-cols-12' style={{rowGap: '0', columnGap: '1rem'}}>
                                            <div className="col-span-6">
                                                <label htmlFor={`friends[0].friend_name`} className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Friend&apos;s Name*</label>
                                                <Field 
                                                    type="text" 
                                                    id={`friends[0].friend_name`} 
                                                    name={`friends[0].friend_name`} 
                                                    className="w-full h-10 p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"
                                                />
                                                <ErrorMessage name={`friends[0].friend_name`} component="div" className="error text-sm text-[#FF0000]" />
                                            </div>
                                            <div className="col-span-6">
                                                <label htmlFor={`friends[0].friend_email`} className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Friend&apos;s Email*</label>
                                                <Field 
                                                    type="text" 
                                                    id={`friends[0].friend_email`} 
                                                    name={`friends[0].friend_email`} 
                                                    className="w-full h-10 p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"
                                                />
                                                <ErrorMessage name={`friends[0].friend_email`} component="div" className="error text-sm text-[#FF0000]" />
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="friends">
                                    {({ remove }) => (
                                        <div className="">
                                            <div className="mt-3"> {values.friends.slice(1).map((friend, index) => (
                                                <div key={index} className='grid grid-cols-12' style={{rowGap: '0', columnGap: '1rem'}}>
                                                    <div className="col-span-6">
                                                        <label htmlFor={`friends[${index + 1}].friend_name`} className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Friend&apos;s Name*</label>
                                                        <Field
                                                            type="text"
                                                            id={`friends[${index + 1}].friend_name`}
                                                            name={`friends[${index + 1}].friend_name`}
                                                            className="w-full h-10 p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"
                                                        />
                                                        <ErrorMessage name={`friends[${index + 1}].friend_name`} component="div" className="error text-sm text-[#FF0000]" />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <label htmlFor={`friends[${index + 1}].friend_email`} className="block text-sm dark:text-white  font-semibold leading-6 text-gray-900">Friend&apos;s Email*</label>
                                                        <Field
                                                            type="text"
                                                            id={`friends[${index + 1}].friend_email`} 
                                                            name={`friends[${index + 1}].friend_email`}
                                                            className="w-full h-10 p-4 border  text-gray-900 rounded focus:outline-none shadow-sm"
                                                        />
                                                        <ErrorMessage name={`friends[${index + 1}].friend_email`} component="div" className="error text-sm text-[#FF0000]" />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <button type="button" className="mt-0 text-sm text-[#FF0000]" onClick={() => remove(index + 1)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                        </div> 
                                    )}
                                    </FieldArray>
                                    <div className="col-span-12 center">
                                        <button type="button" 
                                            onClick={() => {
                                                if (!addingFriend) {
                                                setAddingFriend(true);
                                                setFieldValue('friends', [...values.friends, { friend_name: '', friend_email: '' }]);
                                                setAddingFriend(false);
                                                }
                                            }}
                                            className="text-[#346aa9]">
                                            + Add Another Friend
                                        </button>
                                    </div>
                                    <div className="grid grid-cols-12 gap-4 mt-3">
                                        <div className="col-span-12 mx-auto">
                                            <button 
                                                type="submit" 
                                                disabled={isLoading ? true : false}
                                                page_name="Report Profile Page"
                                                form_name="forward-to-friend-form"
                                                className="bg-[#1b5df3] block rounded px-8 py-2 text-center text-sm font-semibold text-white shadow-sm btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FriendFields;
